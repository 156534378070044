import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '80%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			config: {
				sorting: true,
				fields: {
					id: {
						config: {
							visible: false,
							width: '250px'
						}
					},
					name: {
						config: {
							sorting: 'demo.name'
						}
					},
					field: {
					},
					field_decimal: {
						config: {
							precision: 2
						}
					}
				}
			},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		}
	}
}