import { defineAsyncComponent } from 'vue';

export default {
	description: "Доступ",
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '50%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		}
	}
}