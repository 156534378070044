<script lang="jsx">
import { h, resolveDirective, withDirectives, defineComponent } from 'vue';

import TreeController from './controller';

export default defineComponent({
	props: {
		data: {
			type: Array,
			default: () => []
		},
		idprop: {
			type: String,
			default: 'id'
		},
		textprop: {
			type: String,
			default: 'name'
		},
		iconprop: {
			type: String,
			default: null
		},
		height: {
			type: String,
			default: null
		},
		notion: {
			type: Function,
			default: (data) => (data.text)
		}
	},

	watch: {
		data: {
			immediate: true,
			handler(newval) {
				if (newval) this.createTree(newval);
			}
		}
	},

	setup(props, { emit }) {
		const controller = new TreeController(props, emit);

		let dragElement = null;
		let heightZone = null;
		let overelement = null;
		let dragmode = null;
		let handleTimeout = null;

		const findElement = (tree, name) => tree ? (tree.localName.toUpperCase() == name ? tree : findElement(tree.parentElement, name)) : null;

		const onDragover = (e, item) => {
			if (controller.isChild(dragElement, item.id) || (dragElement.parent && dragElement.parent.id == item.id)) {
				dragmode = null;
			} else {
				e.preventDefault();

				const el = findElement(e.target, 'LI');

				if (el) {
					if (overelement != el) {
						if (overelement) {
							overelement.classList.remove("drag-above");
							overelement.classList.remove("drag-on");
							overelement.classList.remove("drag-below");
						}

						overelement = el;
						dragmode = null;
					}

					if (e.offsetY < heightZone && dragmode != 1) {
						overelement.classList.add("drag-above");
						overelement.classList.remove("drag-on");
						overelement.classList.remove("drag-below");

						dragmode = 1;
						if (handleTimeout) clearTimeout(handleTimeout);

					} else if (e.offsetY >= heightZone && e.offsetY <= heightZone * 2 && dragmode != 2 && item.folder) {
						overelement.classList.remove("drag-above");
						overelement.classList.add("drag-on");
						overelement.classList.remove("drag-below");

						if (item.folder && !item.expanded) {
							handleTimeout = setTimeout(() => item.expanded = true, 1000);
						} else {
							if (handleTimeout) clearTimeout(handleTimeout);
						}

						dragmode = 2;

					} else if (e.offsetY > heightZone * 2 && dragmode != 3) {
						overelement.classList.remove("drag-above");
						overelement.classList.remove("drag-on");
						overelement.classList.add("drag-below");

						dragmode = 3;
						if (handleTimeout) clearTimeout(handleTimeout);
					}
				}
			}
		}

		const onDrop = (item) => {
			if (dragElement) {
				controller.deleteNode(dragElement.id);

				switch (dragmode) {
					case 1: {
						const items = item.parent && item.parent.children ? item.parent && item.parent.children : controller.tree.value;

						for (let i = 0; i < items.length; i++) {
							if (items[i].id == item.id) {
								items.splice(i, 0, dragElement);
								dragElement.parent = item.parent;
								dragElement.data.parent = item.data.parent;

								emit('node:dragging:finish', dragElement, item, dragmode)

								break;
							}
						}
					}

						break;

					case 3:
						if (item.parent && item.parent.children) {
							for (let i = 0; i < item.parent.children.length; i++) {
								if (item.parent.children[i].id == item.id) {
									item.parent.children.splice(i + 1, 0, dragElement);
									dragElement.parent = item.parent;
									dragElement.data.parent = item.data.parent;

									emit('node:dragging:finish', dragElement, item, dragmode)

									break;
								}
							}
						}

						break;

					default:
						if (item.children) {
							item.children.push(dragElement);
							dragElement.parent = item;
							dragElement.data.parent = item.data.id;

							emit('node:dragging:finish', dragElement, item, dragmode)
						}

						break;
				}

				controller.selectid.value = dragElement.id;
			}

			if (overelement) {
				overelement.classList.remove("drag-above");
				overelement.classList.remove("drag-on");
				overelement.classList.remove("drag-below");
			}
		}

		const onDragstart = (e, item) => {
			heightZone = e.target.scrollHeight / 3;

			dragElement = item;

			e.dataTransfer.effectAllowed = 'move';
		}

		const onDragend = () => {
			dragElement = null;

			if (overelement) {
				overelement.classList.remove("drag-above");
				overelement.classList.remove("drag-on");
				overelement.classList.remove("drag-below");
			}
		}

		const renderTree = (items, level = 0, root = false) => {
			const vFocus = resolveDirective('focus');

			const elements = [];

			for (const item of items) {
				const content = [];

				if (item.folder) {
					content.push(h('i', { class: { "tree-arrow": true, expanded: item.expanded, "has-child": item.folder, "ltr": true } }));
					content.push(h('i', { class: { "icon": true, "icon-folder-open-o": item.expanded, "icon-folder-o": !item.expanded, "pl-1": true } }));
				} else {
					content.push(<i class="tree-arrow ltr"></i>);

					const options = { class: { 'pl-1': true } };
					options.class[item.icon] = true;

					content.push(h("i", options));
				}

				if (item.isEditing) {
					let text = item.text;

					content.push(
						h('span',
							{ class: "tree-anchor" },
							withDirectives(h('input',
								{
									type: 'text',
									class: 'tree-input',
									value: item.text,
									ref: 'treeInput',
									onInput: (e) => text = e.target.value,
									onBlur: () => controller.stopEditing(text),
									onKeydown: (e) => {
										e.stopPropagation();

										switch (e.keyCode) {
											case 13:
												controller.stopEditing(text);

												break;

											case 27:
												controller.stopEditing(false);

												break;

											default:
												break;
										}
									}
								}
							), [[vFocus]])
						)
					);
				} else {
					content.push(<span class="tree-anchor">{props.notion(item)}</span>);
				}

				elements.push(
					h(
						'li',
						{
							class: { "draggable": true, "tree-node": true, "has-child": item.folder, selected: item.id == controller.selectid.value },
							draggable: !item.isEditing,
							'data-id': item.id,

							onClick: () => {
								if (!item.isEditing) {
									if (item.folder) {
										if (!item.expanded) emit('node:open', item, controller);

										item.expanded = !item.expanded;
									}

									controller.setActive(item);
								}
							},

							onDblclick: () => emit('node:dblclick', item),

							onDragstart: (e) => onDragstart(e, item),

							onDragend: () => onDragend(),

							onDragover: (e) => onDragover(e, item),

							onDrop: () => onDrop(item),

							onContextmenu: (e) => {
								controller.setActive(item);

								emit('node:contextmenu', e, item);
							}
						},
						h('div', { class: "tree-content", style: { "padding-left": `${level * 15}px` } }, content)
					)
				);

				if (item.children && item.expanded) elements.push(renderTree(item.children, level + 1));
			}

			return h('ul', { class: root ? 'tree-root' : 'tree-children' }, elements);
		}

		return {
			tree: controller.tree,
			selectid: controller.selectid,
			onKeydown: (event) => controller.onKeydown(event),
			createTree: (items) => controller.createTree(items),
			deleteNode: (id) => controller.deleteNode(id),
			createNode: (item, nodes, parent) => controller.createNode(item, nodes, parent),
			setActive: (node) => controller.setActive(node),
			findNode: (id) => controller.findNode(id),
			startEditing: controller.startEditing,
			stopEditing: controller.stopEditing,
			renderTree
		}
	},

	render() {
		return h(
			'div',
			{
				class: "tree tree-small",
				style: this.height ? { height: this.height, overflow: 'hidden auto' } : {},
				tabindex: "-1",
				onKeydown: this.onKeydown
			},
			this.renderTree(this.tree, 0, true)
		)
	}
})
</script>

<style>
.tree {
	overflow: auto;
}

.tree-root,
.tree-children {
	list-style: none;
	padding: 0;
}

.tree > .tree-root,
.tree > .tree-filter-empty {
	padding: 3px;
	box-sizing: border-box;
}

.tree.tree-- .tree-node:not(.selected) > .tree-content:hover {
	background: transparent;
}

.tree-node {
	white-space: nowrap;
	display: flex;
	flex-direction: column;
	position: relative;
	box-sizing: border-box;
}

.tree-content {
	display: flex;
	align-items: center;
	padding: 3px;
	cursor: pointer;
	width: 100%;
	box-sizing: border-box;
}

.tree-node:not(.selected) > .tree-content:hover {
	background: #f6f8fb;
}

.tree-node.selected > .tree-content {
	background-color: #e7eef7;
}

.tree-node.disabled > .tree-content:hover {
	background: inherit;
}

.tree-arrow {
	flex-shrink: 0;
	height: 30px;
	cursor: pointer;
	margin-left: 25px;
	width: 0;
}

.tree-arrow.has-child {
	margin-left: 0;
	width: 25px;
	position: relative;
}

.tree-arrow.has-child:after {
	border: 1.5px solid #494646;
	position: absolute;
	border-left: 0;
	border-top: 0;
	left: 9px;
	top: 50%;
	height: 9px;
	width: 9px;
	transform: rotate(-45deg) translateY(-50%) translateX(0);
	transition: transform 0.25s;
	transform-origin: center;
}

.tree-arrow.has-child.rtl:after {
	border: 1.5px solid #494646;
	position: absolute;
	border-right: 0;
	border-bottom: 0;
	right: 0px;
	top: 50%;
	height: 9px;
	width: 9px;
	transform: rotate(-45deg) translateY(-50%) translateX(0);
	transition: transform 0.25s;
	transform-origin: center;
}

.tree-arrow.expanded.has-child:after {
	transform: rotate(45deg) translateY(-50%) translateX(-5px);
}

.tree-checkbox {
	flex-shrink: 0;
	position: relative;
	width: 30px;
	height: 30px;
	box-sizing: border-box;
	border: 1px solid #dadada;
	border-radius: 2px;
	background: #fff;
	transition: border-color 0.25s, background-color 0.25s;
}

.tree-checkbox:after,
.tree-arrow:after {
	position: absolute;
	display: block;
	content: "";
}

.tree-checkbox.checked,
.tree-checkbox.indeterminate {
	background-color: #3a99fc;
	border-color: #218eff;
}

.tree-checkbox.checked:after {
	box-sizing: content-box;
	border: 1.5px solid #fff; /* probably width would be rounded in most cases */
	border-left: 0;
	border-top: 0;
	left: 9px;
	top: 3px;
	height: 15px;
	width: 8px;
	transform: rotate(45deg) scaleY(0);
	transition: transform 0.25s;
	transform-origin: center;
}

.tree-checkbox.checked:after {
	transform: rotate(45deg) scaleY(1);
}

.tree-checkbox.indeterminate:after {
	background-color: #fff;
	top: 50%;
	left: 20%;
	right: 20%;
	height: 2px;
}

.tree-anchor {
	flex-grow: 2;
	outline: none;
	display: flex;
	text-decoration: none;
	color: #343434;
	vertical-align: top;
	margin-left: 3px;
	line-height: 24px;
	padding: 3px 6px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.tree-node.selected > .tree-content > .tree-anchor {
	outline: none;
}

.tree-node.disabled > .tree-content > .tree-anchor {
	color: #989191;
	background: #fff;
	opacity: 0.6;
	cursor: default;
	outline: none;
}

.tree-input {
	display: block;
	width: 100%;
	height: 24px;
	line-height: 24px;
	outline: none;
	border: 1px solid #3498db;
	padding: 0 4px;
}

.l-fade-enter-active,
.l-fade-leave-active {
	transition: opacity 0.3s, transform 0.3s;
	transform: translateX(0);
}

.l-fade-enter,
.l-fade-leave-to {
	opacity: 0;
	transform: translateX(-2em);
}

.tree-small .tree-anchor {
	line-height: 15px;
}

.tree-small .tree-checkbox {
	width: 20px;
	height: 20px;
}

.tree-small .tree-arrow {
	height: 20px;
}

.tree-small .tree-checkbox.checked:after {
	left: 7px;
	top: 3px;
	height: 11px;
	width: 5px;
}

.tree-node.has-child.loading > .tree-content > .tree-arrow,
.tree-node.has-child.loading > .tree-content > .tree-arrow:after {
	border-radius: 50%;
	width: 15px;
	height: 15px;
	border: 0;
}

.tree-node.has-child.loading > .tree-content > .tree-arrow {
	font-size: 3px;
	position: relative;
	border-top: 1.1em solid rgba(45, 45, 45, 0.2);
	border-right: 1.1em solid rgba(45, 45, 45, 0.2);
	border-bottom: 1.1em solid rgba(45, 45, 45, 0.2);
	border-left: 1.1em solid #2d2d2d;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	left: 5px;
	-webkit-animation: loading 1.1s infinite linear;
	animation: loading 1.1s infinite linear;
	margin-right: 8px;
}

@-webkit-keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.drag-above,
.drag-below,
.drag-on {
	position: relative;
	z-index: 1;
}

.drag-on > .tree-content {
	background: #fafcff;
	outline: 1px solid #7baff2;
}

.drag-above > .tree-content::before,
.drag-below > .tree-content::after {
	display: block;
	content: "";
	position: absolute;
	height: 8px;
	left: 0;
	right: 0;
	z-index: 2;
	box-sizing: border-box;
	background-color: #3367d6;
	border: 3px solid #3367d6;
	background-clip: padding-box;
	border-bottom-color: transparent;
	border-top-color: transparent;
	border-radius: 0;
}

.drag-above > .tree-content::before {
	top: 0;
	transform: translateY(-50%);
}

.drag-below > .tree-content::after {
	bottom: 0;
	transform: translateY(50%);
}

.tree-dragnode {
	padding: 10px;
	border: 1px solid #e7eef7;
	position: fixed;
	border-radius: 8px;
	background: #fff;
	transform: translate(-50%, -110%);
	z-index: 10;
}
</style>
