export default {
	forms: {
		edit: {
			config: {
				fields: {
					value: {},
					name: {},
					description: {}
				}
			}
		},
		list: {
			config: {
				fields: {
					value: {},
					name: {},
					description: {}
				}
			}
		},
		select: {
			panel: {
				height: '500px',
				width: '40%'
			},
			config: {
				fields: {
					value: {},
					name: {}
				}
			}
		}
	}
}