<template>
	<template v-for="field of items" :key="field.name">
		<div class="block" draggable="true" @dragstart="(e) => dragstart(e, field)" @dragover="(e) => dragover(e, field)" @drop="(e) => drop(e, field)" v-if="!field.hide">
			<input type="checkbox" :id="`id_${field.name}`" v-model="field.visible" />
			<label :for="`id_${field.name}`">{{ field.description ? ` ${field.description}` : field.name }}</label>
		</div>
	</template>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
	inheritAttrs: false,

	props: {
		fields: {
			type: Object,
			default: () => ({})
		}
	},

	setup(props) {
		const items = ref([]);

		let dragElement = null;

		for (const i in props.fields) {
			items.value.push({
				name: i,
				visible: props.fields[i].config.visible,
				hide: props.fields[i].config.hide,
				description: props.fields[i].description
			});
		}

		const dragstart = (e, field) => {
			e.dataTransfer.dropEffect = 'move';
			e.dataTransfer.effectAllowed = 'move';

			dragElement = field;
		}

		const dragover = (e, field) => field.name != dragElement.name && e.preventDefault();

		const drop = (e, field) => {
			if (dragElement) {
				for (const i in items.value) {
					if (items.value[i].name == dragElement.name) {
						items.value.splice(i, 1);

						break;
					}
				}

				for (const i in items.value) {
					if (items.value[i].name == field.name) {
						items.value.splice(Number(i) + 1, 0, dragElement);

						break;
					}
				}
			}
		}

		const getFields = () => items.value.map(item => ({ name: item.name, description: item.description ? item.description : item.name, visible: item.visible }));

		return {
			items, dragstart, dragover, drop, getFields
		}
	}
})
</script>

<style>
.block {
	position: relative;
	padding: 0.5rem 0.1rem 0.1rem 0.5rem;
	margin-bottom: 0.25rem;
	border: 1px solid transparent;
	border-radius: 0.1875rem;
	background-color: #f3f3f3;
	border-color: #939393;
}
</style>
