import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			config: {
				sorting: true,
				fields: {
					id: {
						config: {
							visible: false,
							width: '250px'
						}
					},
					code: {
						description: 'Код',
						config: {
							width: '40px',
							sorting: 'country.code'
						}
					},
					name: {
						config: {
							visible: true,
							width: '400px',
							sorting: 'country.name'
						}
					},
					namefull: {
						config: {
							sorting: 'country.namefull'
							// visible: false
						}
					}
				}
			},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '70%',
				height: '500px'
			},
			config: {
				fields: {
					code: {
						config: {
							width: '100px'
						}
					},
					name: {
					},
					namefull: {}
				}
			}
		}
	}
}