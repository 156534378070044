export default {
	forms: {
		edit: {
			config: {
				fields: {
					id: {},
					region: {},
					code: {},
					name: {}
				}
			}
		},
		list: {
			config: {
				fields: {
					region: {},
					code: {},
					name: {}
				}
			}
		},
		select: {
			panel: {
				width: '80%'
			},
			config: {
				fields: {
					region: {},
					code: {},
					name: {}
				}
			}
		}
	}
}