import { defineAsyncComponent } from 'vue';

export default {
	description: "Биопсии из внешних систем",
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '90%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		}
	}
}