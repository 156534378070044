import { computed, nextTick, ref } from "vue";
import DBStoreRecord from "@/core/db_store_record";
import { genGUID, isAsync, isVisible, textareaInsertText } from "@/helpers/utils";
import { select as selectForm } from "@/core/db";
import { list } from "@/api/templates";

export default class DBMultiEditController {
	public store: DBStoreRecord;
	public data: any;
	public form: any;
	public inputs: any;
	public structure: any;
	public field: any;
	public label: string;
	public labelId: string;
	public readonly: any;
	public menuShow: any;
	public menuItems: any;

	constructor(public props: any) {
		this.form = props.form;
		this.store = this.form.store;
		this.data = this.store.data;
		this.label = '';
		this.labelId = '';
		this.field = ref(this.props.field);
		this.inputs = ref([]);
		this.menuShow = ref<number>(0);
		this.menuItems = ref([]);

		this.init();

		this.readonly = computed(
			() => {
				return false;
			}
		)
	}

	getFieldModel(fields: any, structure: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			return this.getFieldModel(fields, structure.fields[field].model);
		} else {
			return structure.fields[field];
		}
	}

	init() {
		const field = this.field;

		this.structure = this.getFieldModel(field.value.split('.'), this.store.state);

		if (this.structure) {
			this.label = typeof this.props.label == 'boolean' ? this.structure.description : this.props.label;
			this.labelId = `${field.value}_` + genGUID();
		}
	}

	get value() {
		return this.store.getValue(this.field.value);
	}

	set value(value: any) {
		this.store.setValue(this.field.value, value);
	}

	getValue(index: number) {
		const value: any = this.value ? this.value.split('|') : [];

		return value.length > 0 && index < value.length ? value[index] : '';
	}

	setValue(index: number, value: any) {
		const valueData: any = this.value ? this.value.split('|') : [];

		valueData[index] = '';

		if (valueData.length > 0 && index < valueData.length) valueData[index] = value;

		this.value = valueData.join('|');

		this.updateTextareaHeight();
	}

	updateTextareaHeight() {
		for (const i in this.props.fields) {
			const el = this.inputs[i];

			if (isVisible(el)) el.style['height'] = (el.scrollHeight - 1) + 'px';
		}
	}

	setTemplateValue(key: number, value: any) {
		const el = this.inputs[key];

		textareaInsertText(el, value.template);

		this.setValue(key, el.value);

		nextTick(() => this.updateTextareaHeight());
	}

	async templates(key: number) {
		const result = await selectForm(
			'templates',
			'',
			null,
			{
				default: {
					document_type: this.store.model.id,
					field: `${this.field.value}_${key}`,
					template: this.inputs[key].value
				},
				filters: [
					{
						where: '"templates"."document_type"=:document_type and "templates"."field"=:field',
						params: {
							field: `${this.field.value}_${key}`,
							document_type: this.store.model.id
						}
					}
				]
			}
		)

		if (result) this.setTemplateValue(key, result);
	}

	async createTemplatesMenu(key: number) {
		const result: any = [
			{
				icon: <i class="icon icon-file"> </i>,
				caption: 'Шаблоны',
				onClick: async () => await this.templates(key)
			}
		];

		const items: any = [];

		const response: any = await list(this.store.model.id, `${this.field.value}_${key}`);

		if (response.length > 0) {
			for (const template of response) {
				if (template.items.length > 0) {
					items.push({
						icon: <i class="icon icon-file"> </i>,
						caption: template.template,
						title: template.template,
						items: template.items.map((el: any) => ({
							icon: <i class="icon icon-file"> </i>,
							caption: el.template,
							title: el.template,
							onClick: async () => this.setTemplateValue(key, el)
						}))
					})
				} else {
					items.push({
						icon: <i class="icon icon-file"> </i>,
						caption: template.template,
						title: template.template,
						onClick: async () => this.setTemplateValue(key, template)
					})
				}
			}
		}

		if (items.length > 0) {
			result.push({ caption: '-' });

			items.forEach((item: any) => result.push(item));
		}

		return result;
	}

	async openMenu(menuItems: any) {
		const items: any = this.menuItems.value;

		items.splice(0, items.length);

		const _menuItems = typeof menuItems == 'function' ? (isAsync(menuItems) ? await menuItems(this) : menuItems(items)) : menuItems;

		_menuItems.forEach((el: any) => items.push(el))
	}

	/**
	 * Открытие меню textarea
	 * @param event 
	 */

	async onOpenMenuTextarea(key: number) {
		const items = await this.createTemplatesMenu(key - 1);

		setTimeout(() => this.menuShow.value = key, 0);

		this.openMenu(items);
	}
}
