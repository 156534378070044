import { defineAsyncComponent } from 'vue';

export default ({
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		editgroup: {
			panel: {
				width: '80%'
			},
			component: defineAsyncComponent(() => import('./forms/EditGroup.vue'))
		},
		list: {
			config: {},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '60%',
				// height: '500px'
			},
			component: defineAsyncComponent(() => import('./forms/Select.vue'))
		}
	}
})