import access from "./access";
import accounts from "./accounts";
import address_districts from "./address_districts"
import api_test from "./api_test";
import autopsy_research from "./autopsy_research";
import banks from "./banks";
import coato from "./coato";
import connected_equipment from "./connected_equipment";
import contacts from "./contacts";
import contacts_types from "./contacts_types";
import contragents from "./contragents";
import contragents_departments from "./contragents_departments";
import country from "./country";
import death_certificate from "./death_certificate";
import demo from "./demo";
import divisions_mvd from "./divisions_mvd";
import document_types from "./document_types";
import employees from "./employees";
import external_biopsy from "./external_biopsy";
import files from "./files";
import files_versions from "./files_versions";
import fizlica from "./fizlica";
import history_changes from "./history_changes";
import icd10 from "./icd10";
import icdo from "./icdo";
import igh_antibody from "./igh_antibody";
import mailing from "./mailing";
import messages from "./messages";
import nomenclature from "./nomenclature";
import numerators from "./numerators";
import offline from "./offline";
import autopsy_report from "./autopsy_report";
import paid_services from "./paid_services";
import patients from "./patients";
import pgi_research from "./pgi_research";
import positions from "./positions";
import posts from "./posts";
import rates from "./rates";
import receiving_method from "./receiving_method";
import reg_bodies from "./reg_bodies";
import roles from "./roles";
import templates from "./templates";
import test from "./test";
import types_material from "./types_material";
import units from "./units";
import users from "./users";
import users_roles from "./users_roles";
import neoplasm_notice from "./neoplasm_notice";

import bak_direction from "./bak_direction";

import { mergeDeep } from "@/helpers/utils";

const tables: any = {
	access,
	accounts,
	address_districts,
	api_test,
	autopsy_research,
	banks,
	coato,
	connected_equipment,
	contacts,
	contacts_types,
	contragents,
	'contragents.departments': contragents_departments,
	country,
	death_certificate,
	demo,
	divisions_mvd,
	document_types,
	employees,
	external_biopsy,
	files,
	files_versions,
	fizlica,
	history_changes,
	icd10,
	icdo,
	igh_antibody,
	mailing,
	messages,
	nomenclature,
	numerators,
	offline,
	paid_services,
	patients,
	pgi_research,
	positions,
	posts,
	rates,
	receiving_method,
	reg_bodies,
	roles,
	templates,
	test,
	types_material,
	units,
	autopsy_report,
	users,
	'users.roles': users_roles,
	bak_direction,
	neoplasm_notice
}

export const schema: any = {};

export const loadSchema = (dbSchema: any) => {
	for (const key in schema) delete schema[key];

	for (const key in dbSchema) {
		const table: any = tables[key] ? tables[key] : {};

		schema[key] = Object.assign({}, dbSchema[key], table);
	}

	for (const keySchema in schema) {
		const structure = schema[keySchema];

		const fields = structure.fields;

		const key = structure.key;

		if (!fields[key]) {
			fields[key] = {
				[key]: {
					description: 'id',
					type: 'UUID'
				}
			}
		}

		for (const keyField in fields) {
			const field = fields[keyField];

			if (typeof field.type == 'object') {
				if (field.type.table && typeof field.type.table == 'string') {
					field.table = field.type.table;
					field.type.table = schema[field.type.table];
					field.type.table.subtable = true;
					field.ownerField = field.type.table.ownerField;
				}
			}
		}
	}

	// console.log(schema);
}

export const getModel = (name: string) => {
	if (name) {
		const model = schema[name];
		if (model) {
			const _model = mergeDeep({}, model);

			//Если напрямую запрашивается модель табличной части, то удаляем признак "subtable"
			if (name.split('.').length > 1 && _model.subtable) delete _model.subtable;

			return _model;
		} else {
			return tables[name] ? mergeDeep({ offLine: true }, tables[name]) : null;
		}
	} else {
		return null
	}
}
