<script>

import { h } from 'vue'

export default {
	props: {
		height: {
			type: String,
			default: null
		},
		navClass: {
			type: String,
			default: 'nav nav-tabs nav-tabs-highlight'
		},
		activeTab: {
			type: Number,
			default: 0
		}
	},

	data() {
		return {
			active: this.activeTab
		}
	},

	render() {
		const tabItems = [];
		const panelItems = [];

		const styleB = {}

		if (this.height) {
			styleB['height'] = this.height;
			styleB['overflow'] = 'scroll';
			styleB['overflow-x'] = 'hidden';
			styleB['overflow-y'] = 'auto';
		}

		const slots = this.$slots.default();

		for (const i in slots) {
			const slot = slots[i];

			const items = [slot.props.caption];

			if ('badge' in slot.props) {
				items.push(h('span', { class: "badge badge-secondary ml-1" }, slot.props.badge));
			}

			tabItems.push(
				h(
					'li',
					{
						class: 'nav-item unselectable',
						style: { display: !('visible' in slot.props) || slot.props.visible ? 'block' : 'none' }
					},
					[
						h(
							'a',
							{
								class: i == this.active ? "nav-link active" : "nav-link",
								title: slot.props.caption,
								href: "#",
								onClick: e => {
									e.preventDefault();

									if (this.active != i) this.$emit("change", i);

									this.active = i;
								}
							},
							items
						)
					]
				)
			);

			panelItems.push(h('div', { class: i == this.active ? 'tab-pane show active' : 'tab-pane' }, slot));
		}

		const tabs = h('ul', { class: this.navClass }, tabItems);
		const panels = h('div', { class: "tab-content mt-1", style: styleB }, panelItems);

		if (this.active >= slots.length) this.active = 0;

		return h('div', [tabs, panels])
	}
}
</script>

<style>
</style>
