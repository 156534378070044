<template>
	<template v-if="isModal">
		<slot></slot>
	</template>
	<template v-else>
		<div :class="{'card': true, 'fixed-top h-100 rounded-0': fullScreen}">
			<div class="card-header header-elements-inline bg-white">
				<div class="card-title">
					<PanelFun :items="panelFun" v-if="panelFun.length > 0"></PanelFun>
				</div>

				<div class="header-elements">
					<div class="list-icons">
						<a href="#" class="list-icons-item" @click="onCollapse" v-if="collapsed">
							<i :class="collapse? 'icon-arrow-down12': 'icon-arrow-up12'"></i>
						</a>
						<a href="#" class="list-icons-item" @click="onScreenFull" :title="fullScreen? 'Свернуть': 'Развернуть на весь экран'">
							<i :class="fullScreen? 'icon icon-minimize': 'icon icon-maximize'"></i>
						</a>
					</div>
				</div>
			</div>
			<div class="card-body" :style="{display: collapse? 'none': 'block' }">
				<slot></slot>
			</div>
			<div class="card-footer" v-if="panelFunFooter.length > 0">
				<PanelFun :items="panelFunFooter"></PanelFun>
			</div>
		</div>
	</template>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import PanelFun from "@/components/PanelFun.vue";
import { panels, thisPanelModal } from '@/layouts/layouts';

export default defineComponent({
	components: {
		PanelFun
	},

	props: {
		panelFun: {
			type: Array,
			default: () => []
		},
		panelFunFooter: {
			type: Array,
			default: () => []
		},
		panel: {
			type: String,
			default: null
		},
		collapsed: {
			type: Boolean,
			default: false
		}
	},

	setup(props) {
		const fullScreen = ref(false);
		const collapse = ref(false);

		const onScreenFull = () => {
			fullScreen.value = !fullScreen.value;
		}

		const onCollapse = () => {
			collapse.value = !collapse.value;
		}

		const isModal = computed(() => panels.value.some(el => el.key == props.panel && el.modal));

		return {
			fullScreen,
			collapse,
			onScreenFull,
			onCollapse,
			thisPanelModal,
			isModal
		}
	}
})
</script>

<style>
</style>