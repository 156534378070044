<template>
	<div class="dropdown-menu show" :style="style" v-if="controller.menuShow.value">
		<DropdownMenu :items="controller.menuItems.value" :left="left"></DropdownMenu>
	</div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue';
import DropdownMenu from '@/components/DropdownMenu.vue';

export default {
	components: {
		DropdownMenu
	},

	props: {
		style: {
			type: String,
			default: 'right: 0px; left: unset;'
		},
		controller: {
			type: Object,
			default: () => ({})
		},
		left: {
			type: Boolean,
			default: false
		}
	},

	setup(props) {
		const closePopup = () => {
			props.controller.menuShow.value = false;
		}

		onMounted(() => {
			document.addEventListener("click", closePopup);
		})

		onUnmounted(() => {
			document.removeEventListener("click", closePopup);
		})
	}
}
</script>

<style></style>