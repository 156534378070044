<template>
	<div class="list-group list-group-flush" style="padding: 0px" tabindex="-1" @keydown="onKeyDown">
		<template v-for="(item, key) of data.items" :key="key">
			<a href="#" :class="{'list-group-item': true, 'list-group-item-action': true, active: key==data.position}" @click.prevent="activeRow(key)" @dblclick="select">{{getData(item)}}</a>
		</template>
	</div>
</template>

<script>
import { reactive } from 'vue'

export default {
	emits: ['select'],

	props: {
		items: {
			type: Array,
			default: () => []
		},
		position: {
			type: Number,
			default: 0
		},
		field: {
			type: String,
			default: ''
		}
	},

	setup(props, { emit }) {
		const data = reactive({
			items: props.items,
			position: props.position
		});

		const getData = (item) => {
			return props.field && typeof item == 'object' ? item[props.field] : item;
		}

		const activeRow = (position) => {
			data.position = position;
		}

		const home = () => {
			activeRow(0);
		}

		const end = () => {
			activeRow(data.items.length - 1);
		}

		const select = () => {
			if (data.items.length > 0 && data.position >= 0 && data.position < data.items.length) {
				if (props.field && typeof data.items[data.position] == 'object') {
					emit('select', data.items[data.position], data.position);
				} else {
					emit('select', data.position);
				}
			}
		}

		const onKeyDown = (event) => {
			switch (event.keyCode) {
				case 13:
					select();

					event.preventDefault();

					break;

				case 38://up
					if (data.position != null && data.position > 0) {
						activeRow(data.position - 1);

						event.preventDefault();
					}
					break;

				case 40://down
					if (data.position != null && data.position < data.items.length - 1) {
						activeRow(data.position + 1);

						event.preventDefault();
					}
					break;

				case 36://home
					home();

					event.preventDefault();

					break;

				case 35://end
					end();

					event.preventDefault();

					break;

				default:
					break;
			}
		}

		return {
			data,
			onKeyDown,
			activeRow,
			select,
			getData
		}
	}
}
</script>

<style>
</style>