import { beforeSave } from '@/api/death_certificate';
import { toastError } from '@/helpers/toastify';
import dayjs from 'dayjs';
import { defineAsyncComponent } from 'vue';

export default ({
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '90%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					notion: {
						description: 'Свидетельство',
						config: {
							visible: true
						}
					},
					deceased: {
						description: 'Контрагент'
					},
					death_organization: {
						description: 'Организация'
					},
					death_department: {
						description: 'Отделение'
					},
					datedoc: {

					},
					dateofdeath: {

					},
					pathologist: {
						config: {
							visible: true
						}
					}
				}
			}
		}
	},

	async checkBeforeSave(data: any) {
		const { id, number, datedoc, performer, performer_department } = data;

		if (data.datedoc) {
			const response: any = await beforeSave({
				id,
				number,
				performer,
				performer_department,
				year: dayjs(datedoc).year()
			})

			if (response.complete) {
				return true;
			} else {
				toastError(response.message);
			}

			return false;
		}

		return true;
	},

	onBeforeSave: async function (data: any) {
		return this.checkBeforeSave(data);
	}
})