<template>
	<div style="min-height:50px">
		<div class="document-type-name success">
			<div class="document-type-icon success">
				<span class="icon-files-empty"></span>
			</div>
			{{title}}
			<em class="error help-block" v-if="isValidation">*</em>
			<div class="text-muted" v-if="subject">
				{{subject}}
				<em class="error help-block" v-if="isValidation">*</em>
			</div>
			<div v-show="feedback" class="error">{{ feedback }}</div>
		</div>
		<div class="document-upload-wrapper">
			<template v-for="(file, i) of files" :key="file.id">
				<div class="document-upload-name-wrapper" v-if="showDeletionMark || !file.deletionmark">
					<a
						class="pull-right text-muted"
						href="#"
						@click.prevent="()=>deleteFile(i)"
						v-if="!isReadonly && (!file.deletionmark || recoveryDeletionMark)"
					>
						<span class="icon icon-x"></span>
					</a>

					<a href="#" class="document-upload-name" @click.prevent="()=>loadFile(file)" :class="{linethrough: file.deletionmark}">
						<span :class="icon(file.type)" class="mr-1"></span>
						{{file.name}}
					</a>

					<small v-if="showdatetime">{{` (${datetime(file)})`}}</small>
				</div>
			</template>
			<div
				class="document-upload-button-wrapper"
				v-if="(deletionMark ? files.filter(el => !el.deletionmark).length : files.length) < max && !isReadonly"
			>
				<div class="upload-button btn btn-action">
					<span>Добавить файл</span>
					<input type="file" @change="changeFile" accept="image/*, application/pdf" multiple />
				</div>
				<small v-if="maxFileSize > 0" class="form-text text-muted">Максимальный размер файла: {{~~(maxFileSize / 1024000)}}Мб</small>
			</div>
		</div>
		<slot></slot>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import dayjs from 'dayjs';
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { genGUID } from "@/helpers/utils"
import stateStore from "@/store";
import { config } from "@/config";

export default defineComponent({
	inheritAttrs: false,

	emits: ['change', 'delete'],

	props: {
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: ''
		},
		min: {
			type: Number,
			default: 0
		},
		max: {
			type: Number,
			default: 10
		},
		maxFileSize: {
			type: Number,
			default: 1024000 * 3
		},
		subject: {
			type: String,
			default: null
		},
		showdatetime: {
			type: Boolean,
			default: true
		},
		validation: {
			type: Boolean,
			default: true
		},
		readonly: {
			type: Boolean,
			default: null
		},
		filename: {
			type: String,
			default: null
		},
		deleteTitle: {
			type: String,
			default: 'Удалить файл?'
		},
		deleteText: {
			type: String,
			default: ''
		},
		deletionMark: {
			type: Boolean,
			default: false
		},
		showDeletionMark: {
			type: Boolean,
			default: false
		},
		recoveryDeletionMark: {
			type: Boolean,
			default: false
		}
	},

	setup(props, { emit }) {
		const verified = ref(false);
		const valid = ref(false);
		const feedback = ref('');

		const formStore = props.form.store;

		const storeFiles = formStore.createDBStore(props.field)

		const files = storeFiles.data.rows;

		const uploadFiles = formStore.files;

		const field = formStore.state.fields[props.field];

		const datetime = (file) => dayjs(file.created_at).format('DD.MM.YYYY HH:mm:ss');

		const deleteFile = (index) => {
			if (props.deletionMark) {
				if (files[index].deletionmark) {
					Swal.fire({
						title: "Восстановить файл?",
						showCancelButton: true,
						confirmButtonText: 'Да',
						cancelButtonText: 'Отмена'
					}).then(({ value }) => {
						if (value) {
							files[index].deletionmark = false;
							emit('delete', files[index]);
						}
					})
				} else {
					Swal.fire({
						title: props.deleteTitle,
						text: props.deleteText,
						showCancelButton: true,
						confirmButtonText: 'Да',
						cancelButtonText: 'Отмена'
					}).then(({ value }) => {
						if (value) {
							files[index].deletionmark = true;
							emit('delete', files[index]);
						}
					})
				}
			} else {
				Swal.fire({
					title: props.deleteTitle,
					text: props.deleteText,
					showCancelButton: true,
					confirmButtonText: 'Да',
					cancelButtonText: 'Отмена'
				}).then(({ value }) => {
					if (value) {
						emit('delete', files[index]);

						delete uploadFiles[files[index].id];

						files.splice(index, 1)
					}
				})
			}
		}

		const loadFile = (file) => {
			const href = uploadFiles[file['id']] ? URL.createObjectURL(uploadFiles[file['id']]) : `${config.server}/api/files/${file['id']}`;

			const link = document.createElement('a');
			link.setAttribute('href', href);
			link.setAttribute('download', file['name']);
			link.click();
		}

		const icon = (type) => {
			switch (type) {
				case 'application/pdf':
					return 'fa fa-file-pdf-o';

				case 'image/jpeg':
				case 'image/png':
					return 'fa fa-file-image-o';

				case 'application/vnd.ms-excel':
				case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
					return 'fa fa-file-excel-o';

				case 'application/msword':
					return 'fa fa-file-word-o';

				default:
					return 'fa fa-file';
			}
		}

		const fileRename = (fileName, pref = null) => {
			if (props.filename) {
				const posA = fileName.lastIndexOf('.');
				const posB = props.filename.lastIndexOf('.');
				if (posB == -1) {
					return props.filename + (pref ? String(pref) : '') + fileName.substr(posA);
				} else {
					return props.filename.substr(0, posB) + (pref ? String(pref) : '') + fileName.substr(posA);
				}
			} else {
				return fileName;
			}
		}

		const fileExists = (fileName) => {
			for (const file of storeFiles.data.rows) {
				if (file.name == fileName) return true;
			}

			return false;
		}

		const changeFile = (e) => {
			feedback.value = '';

			for (const file of e.target.files) {
				let pref = 1;

				let name = fileRename(file.name, pref);

				if (props.filename) {
					while (fileExists(name)) {
						name = fileRename(file.name, ++pref);
					}
				}

				const recFile = {
					id: genGUID(),
					name,
					type: file.type,
					size: file.size,
					lasteditor: stateStore.state.user['id'],
					_lasteditor: stateStore.state.user['name'],
					updated_at: dayjs().format(),
					author: stateStore.state.user['id'],
					_author: stateStore.state.user['name'],
					created_at: dayjs().format(),
					deletionmark: false
				};

				uploadFiles[recFile['id']] = file;

				feedback.value = '';

				if (props.maxFileSize == 0 || recFile.size <= props.maxFileSize) {
					if ((props.deletionMark ? files.filter(el => !el.deletionmark).length : files.length) < props.max) {
						storeFiles.push(recFile);
						emit('change', recFile);
					} else {
						feedback.value = `Максимальное количество загружаемых файлов "${props.max}"`
					}
				} else {
					feedback.value = `Размер файла "${recFile.name}" не должен превышать ${~~(props.maxFileSize / 1024000)}Мб`
				}
			}
		}

		const validation = () => {
			//Проверку на минимальное кол-во файлов
			if (props.validation && field.validation) {
				const value = formStore.data[props.field] ? formStore.data[props.field] : [];

				for (const i in field.validation) {
					const result = field.validation[i](value, formStore.data);
					if (result !== false) {
						feedback.value = result !== false ? result : null;

						return result;
					}
				}
			}

			return false;
		}

		onMounted(() => {
			if (field.validation && Object.keys(field.validation).length)
				props.form.addValidation(validation);
		})

		onUnmounted(() => {
			if (field.validation && Object.keys(field.validation).length)
				props.form.delValidation(validation);
		})

		return {
			files,
			icon,
			changeFile,
			deleteFile,
			datetime,
			loadFile,
			title: computed(() => field.description),
			verified,
			valid,
			feedback,
			isValidation: computed(() => props.validation && !!field.validation && Object.keys(field.validation).length),
			isReadonly: computed(() => typeof props.readonly == 'boolean' ? props.readonly : formStore.state.readonly)
		}
	}
})
</script>

<style scoped>
.document-type-name [class^="icon"] {
	color: #939393;
	line-height: 1;
	font-size: 40px;
	vertical-align: text-top;
}
.panel {
	margin-bottom: 20px;
	background-color: #fff;
	border-radius: 0;
}
.panel-body {
	padding: 15px;
}
.document-type-name {
	font-weight: 500;
	/* min-height: 46px; */
	padding: 8px 10px 8px 50px;
	position: relative;
}
.document-type-icon {
	left: 0;
	position: absolute;
	top: 8px;
}
.document-upload-wrapper {
	padding-left: 50px;
}
.document-upload-name-wrapper {
	padding: 2px 0;
	padding-right: 10px;
	border-top: 1px solid #eee;
	/* margin-right: -15px; */
	font-weight: 500;
}
.pull-right {
	float: right !important;
}
.document-upload-name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 80%;
}
.document-upload-button-wrapper {
	padding: 8px 0;
	padding-right: 10px;
	border-top: 1px solid #eee;
	/* margin-right: -15px; */
}
.upload-button {
	position: relative;
	font-weight: 500;
	padding: 3px 12px;
	cursor: pointer;
}
.upload-button input[type="file"] {
	position: absolute;
	cursor: pointer;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

input[type="file"] {
	display: block;
}

.hidden {
	display: none !important;
}

.document-type-name.success {
	color: #59bc42;
}

.document-type-icon.success {
	color: #59bc42;
}

.error {
	color: red;
	font-size: 12px;
	font-weight: 500;
}

.linethrough {
	text-decoration: line-through;
}
</style>
