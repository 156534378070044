import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			config: {
				fields: {
					login: {
					},
					name: {
					},
					email: {
					},
					roles: {
						fields: {
							owner: {
								config: {
									visible: false
								}
							},
							role: {}
						}
					},
					files: {
						fields: {
							// owner: {},
							name: {},
							storever: {},
							size: {},
							description: {},
							author: {},
							created_at: {}
						}
					}
				}
			},
			panel: {
				// height: '200px',
				width: '80%'
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			config: {
				sorting: true,
				fields: {
					login: {
						config: {
							sorting: '"users"."login"'
						}
					},
					name: {
						config: {
							sorting: '"users"."name"'
						}
					},
					email: {
					},
					employee: {
						config: {
							visible: false
						}
					},
					last_login: {
						config: {
							sorting: '"users"."last_login"'
						}
					}
				}
			},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				height: '500px',
				width: '70%'
			},
			config: {
				fields: {
					login: {
					},
					name: {
					}
				}
			}
		}
	}
}