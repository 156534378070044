import { createRouter, createWebHistory } from "vue-router";
import { breadCrumb, closeAllPanels, pageTitle, panels, setCaption } from "@/layouts/layouts";

import Bus from '@/core/bus';
import stateStore from "@/store"

const routes = [
	{
		path: '',
		component: () => import('@/layouts/Panels.vue'),
		children: [
			{
				path: '',
				component: () => import('@/views/Main.vue'),
				meta: {
					title: 'Главная'
				}
			},
			{
				path: '/map',
				component: () => import('@/views/LeafletMap.vue'),
				meta: {
					title: 'Карта'
				}
			},
			{
				path: '/dbform',
				component: () => import('@/views/dbform/index.vue'),
				meta: {
					title: 'DB-form'
				}
			},
			{
				path: '/select',
				component: () => import('@/views/Testings/Select.vue'),
				meta: {
					title: 'Компонент выбора'
				}
			},
			{
				path: '/comments',
				component: () => import('@/views/Testings/Comments.vue'),
				meta: {
					title: 'Комментарии'
				}
			},
			{
				path: '/printbarcodes',
				component: () => import('@/views/PrintBarcodes.vue'),
				meta: {
					title: 'Печать штрих-кодов'
				}
			},
			{
				path: '/demo1',
				component: () => import('@/views/Testings/Demo1.vue'),
				meta: {
					title: 'Demo 1'
				}
			},
			{
				path: '/development',
				component: () => import('@/views/Testings/Development.vue'),
				meta: {
					title: 'Development'
				}
			},
			{
				path: '/css_test',
				component: () => import('@/views/Testings/css.vue'),
				meta: {
					title: 'CSS'
				}
			},
			{
				path: '/pgi/reports',
				component: () => import('@/reports/index.vue'),
				meta: {
					title: 'Отчетность'
				}
			},
			{
				path: '/pgi/reports/journals/directed',
				component: () => import('@/reports/pgi/Directed/index.vue'),
				meta: {
					roles: ['admin', 'pgi'],
					title: 'Журнал регистрации материала, направляемого на исследование'
				}
			},
			{
				path: '/pgi/reports/journals/submitted',
				component: () => import('@/reports/pgi/Submitted/index.vue'),
				meta: {
					roles: ['admin', 'pgi', 'auditor'],
					title: 'Журнал регистрации материала, поступившего на исследование'
				}
			},
			{
				path: '/pgi/reports/biopsy',
				component: () => import('@/reports/pgi/Biopsy/index.vue'),
				meta: {
					roles: ['admin', 'pgi', 'auditor'],
					title: 'Отчет по биопсиям'
				}
			},
			{
				path: '/pgi/reports/organsystems',
				component: () => import('@/reports/pgi/OrganSystems/index.vue'),
				meta: {
					roles: ['admin', 'pgi', 'auditor'],
					title: 'Отчет по системам органов'
				}
			},
			{
				path: '/pgi/reports/diagnosis',
				component: () => import('@/reports/pgi/Diagnosis/index.vue'),
				meta: {
					roles: ['admin', 'pgi', 'auditor'],
					title: 'Отчет по диагнозам'
				}
			},
			{
				path: '/pgi/reports/biopsy_payment',
				component: () => import('@/reports/pgi/BiopsyPayment/index.vue'),
				meta: {
					roles: ['admin', 'pgi', 'auditor'],
					title: 'Отчет по платным биопсиям'
				}
			},
			{
				path: '/pgi/reports/assistants',
				component: () => import('@/reports/pgi/Assistants/index.vue'),
				meta: {
					roles: ['admin', 'pgi'],
					title: 'Отчет по лаборантам'
				}
			},
			{
				path: '/pgi/reports/doctors',
				component: () => import('@/reports/pgi/Doctors/index.vue'),
				meta: {
					roles: ['admin', 'pgi'],
					title: 'Отчет по врачам'
				}
			},
			{
				path: '/pgi/reports/pgi_research',
				component: () => import('@/reports/pgi/PGIResearch/index.vue'),
				meta: {
					roles: ['admin', 'pgi'],
					title: 'Список исследований'
				}
			},
			{
				path: '/pgi/reports/pgi_cutting',
				component: () => import('@/reports/pgi/PGICutting/index.vue'),
				meta: {
					roles: ['admin', 'pgi'],
					title: 'Проверка номеров исследований'
				}
			},
			{
				path: '/posts/:guid',
				component: () => import('@/views/Post.vue'),
				meta: {
					title: ''
				}
			},
			{
				path: '/help',
				component: () => import('@/views/Help.vue'),
				meta: {
					title: 'Помощь'
				}
			},
			{
				path: '/patients',
				component: () => import('@/tables/patients/forms/List.vue'),
				meta: {
					roles: ['admin', 'pgi', 'auditor'],
					title: 'Контрагенты',
				}
			},
			{
				path: '/patients/:id',
				component: () => import('@/tables/patients/forms/List.vue'),
				meta: {
					roles: ['admin', 'pgi', 'auditor'],
					title: 'Контрагенты',
				}
			},
			{
				path: '/pgi_research',
				component: () => import('@/tables/pgi_research/forms/List.vue'),
				meta: {
					roles: ['admin', 'pgi', 'auditor'],
					title: 'Журнал регистрации заявлений',
				}
			},
			{
				path: '/pgi_research/:id',
				component: () => import('@/tables/pgi_research/forms/List.vue'),
				meta: {
					roles: ['admin', 'pgi', 'auditor'],
					title: 'Журнал регистрации заявлений',
				}
			},
			{
				path: '/autopsy_research',
				component: () => import('@/tables/autopsy_research/forms/List.vue'),
				meta: {
					roles: ['admin', 'autopsy', 'auditor', 'checker'],
					title: 'Лабораторный журнал',
				}
			},
			{
				path: '/autopsy_research/:id',
				component: () => import('@/tables/autopsy_research/forms/List.vue'),
				meta: {
					roles: ['admin', 'autopsy', 'auditor', 'checker'],
					title: 'Лабораторный журнал',
				}
			},
			{
				path: '/death_certificate',
				component: () => import('@/tables/death_certificate/forms/List.vue'),
				meta: {
					roles: ['admin', 'autopsy', 'auditor'],
					title: 'Свидетельства',
				}
			},
			{
				path: '/death_certificate/:id',
				component: () => import('@/tables/death_certificate/forms/List.vue'),
				meta: {
					roles: ['admin', 'autopsy', 'auditor', 'checker'],
					title: 'Свидетельства',
				}
			},
			{
				path: '/reg_bodies',
				component: () => import('@/tables/reg_bodies/forms/List.vue'),
				meta: {
					roles: ['admin', 'autopsy', 'auditor', 'checker'],
					title: 'Журнал регистрации заявлений',
				}
			},
			{
				path: '/tables',
				component: () => import('@/views/ViewTables.vue'),
				meta: {
					title: 'Список таблиц',
					roles: ['admin'],
					breadCrumb: [
						{
							icon: 'icon-table2',
							caption: 'Таблицы'
						}
					]
				}
			},
			{
				path: '/tables/:table',
				component: () => import('@/tables/List.vue'),
				meta: {
					title: 'Таблица'
				}
			},
			{
				path: '/about',
				component: () => import('@/views/About.vue'),
				meta: {
					title: 'О портале'
				}
			},
			{
				path: '/messages',
				component: () => import('@/views/messages.vue'),
				meta: {
					title: 'Сообщения'
				}
			},
			{
				path: '/icons',
				component: () => import('@/views/Icons.vue'),
				meta: {
					title: 'Иконки - e-med'
				}
			},
			{
				path: '/statistics',
				component: () => import('@/views/statistics/Statistics.vue'),
				meta: {
					title: 'Статистика'
				}
			}
		]
	},
	{
		path: '/profile',
		component: () => import('@/views/profile/index.vue'),
		meta: {
			title: 'Профиль',
			breadCrumb: [
				{
					route: '/profile',
					icon: 'icon icon-user',
					caption: 'Профиль'
				}
			]
		},
		children: [
			{
				path: '',
				component: () => import('@/views/profile/page.vue'),
				meta: {
					title: 'Основные данные',
					icon: 'icon icon-more-horizontal',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-book-open mr-2',
							caption: 'Основные данные'
						}
					]
				}
			},
			{
				path: 'page',
				component: () => import('@/views/profile/page.vue'),
				meta: {
					title: 'Основные данные',
					icon: 'icon icon-book-open mr-2',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-book-open mr-2',
							caption: 'Основные данные'
						}
					]
				}
			},
			{
				path: 'equipment',
				component: () => import('@/views/profile/equipment.vue'),
				meta: {
					title: 'Оборудование',
					icon: 'icon icon-printer',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-printer',
							caption: 'Оборудование'
						}
					]
				}
			}
		]
	},
	{
		path: '/user/:guid',
		component: () => import('@/views/UserProfile/index.vue'),
		meta: {
			title: 'Профиль'
		},
		children: [
			{
				path: '',
				component: () => import('@/views/UserProfile/AboutUser.vue'),
				meta: {
					title: 'О пользователе',
					icon: 'icon-user'
				}
			},
			{
				path: 'aboutuser',
				component: () => import('@/views/UserProfile/AboutUser.vue'),
				meta: {
					title: 'О пользователе',
					icon: 'icon-user'
				}
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/views/auth/Login.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/regemail',
		component: () => import('@/views/auth/RegEmail.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/passwordrecovery',
		component: () => import('@/views/auth/PasswordRecovery.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/passwordrecovery/:token',
		component: () => import('@/views/auth/PasswordRecovery.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/registration',
		component: () => import('@/views/auth/Registration.vue'),
		meta: {
			layout: "empty-layout"
		}
	},
	{
		path: '/registration/:token',
		component: () => import('@/views/auth/Registration.vue'),
		meta: {
			layout: "empty-layout"
		}
	},
	{
		path: '/404',
		component: () => import('@/views/Page404.vue')
	},
	{
		path: '/:pathMatch(.*)',
		component: () => import('@/views/Page404.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

Bus.$on('ws-open-document', (data: any) => {
	switch (data.type_document) {
		case '71c4e28b-ae0a-29b2-6e8f-627de1170ed1':
			router.push(`pgi_research/${data.id}`);

			break;

		case '7bab42b6-c307-ddc6-72a4-cfd5cc1df35c':
			router.push(`autopsy_research/${data.id}`);

			break;

		case '8a89ca79-2519-6fe2-ed14-94ed69825e75':
			router.push(`/patients/${data.id}`);

			break;

		default:
			console.log(data);

			break;
	}
});

router.beforeEach(async (to, from) => {
	//Прописывать в роутах роли и проверять доступ при переходах
	if (to.fullPath != from.fullPath) {
		for (const panel of panels.value) {
			if (panel.onBeforeClose) {
				if (!await panel.onBeforeClose()) return false;
			}
		}

		closeAllPanels();

		setCaption('');
	}

	//Получаем статус пользователя
	const user: any = stateStore.state.user.id ? stateStore.state.user : await stateStore.dispatch('status');

	//Если в метаданных прописаны роли, то производим проверку на доступ, если не указана ни одна роль - доступ запрещен
	if (to.meta.roles) {
		const roles: any = to.meta.roles;

		for (const routeRole of roles) {
			if (user.roles.includes(routeRole)) return true;
		}

		return { path: '/404' };
	} else {
		return true;
	}
})

router.afterEach((to) => {
	breadCrumb.value = to.meta.breadCrumb ? to.meta.breadCrumb : [];

	pageTitle.value = to.meta.title ? to.meta.title : null;
})

export default router;
