import { reactive } from "vue";

export const contextMenu = reactive(
	{
		items: [],
		show: false,
		top: '0px',
		left: '0px'
	}
);

export default function (event: any, items: any = [], offsetX = 0, offsetY = 0) {
	event.preventDefault();

	if (items.length > 0) {
		setTimeout(() => {
			contextMenu.items = items;
			contextMenu.top = `${event.pageY + offsetY}px`;
			contextMenu.left = `${event.pageX + offsetX}px`;
			contextMenu.show = true;
		}, 0)
	}
}
