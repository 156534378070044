export default {
	forms: {
		edit: {
			panel: {
				width: '70%'
			},
			config: {
				fields: {
					name: {},
					enter_other: {},
					neoplasms: {},
					endometrial: {},
					placenta: {},
					position: {}
				}
			}
		},
		list: {
			config: {
				fields: {
					name: {},
					neoplasms: {},
					endometrial: {},
					placenta: {},
					position: {}
				}
			}
		},
		select: {
			panel: {
				width: '70%'
			},
			config: {
				view: false,
				fields: {
					name: {},
					enter_other: {
						config: {
							visible: false
						}
					}
				}
			}
		}
	}
}