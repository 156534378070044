import ReconnectingWebSocket from 'reconnecting-websocket';

import stateStore from '@/store'
import { config } from "@/config";
import Bus from '@/core/bus'

//Использовать Bus
class WSClient {
	private ws: any;
	constructor() {
		this.ws = new ReconnectingWebSocket(config.ws);

		this.ws.connectionTimeout = 10000;

		this.ws.onopen = () => {
			stateStore.dispatch('online', true);

			this.send({
				event: 'login',
				data: stateStore.state.token
			});
		};

		this.ws.onmessage = (message: any) => {
			const json = JSON.parse(message.data);

			// console.log(json);

			Bus.$emit('ws-data', json);

			if (json.message) {
				stateStore.dispatch('newMessage', json.message);
			}
		}

		this.ws.onclose = () => {
			stateStore.dispatch('online', false);
		}
	}

	send(message = {}) {
		if (!stateStore.state.online) return false;

		this.ws.send(JSON.stringify(message));

		return true;
	}
}

export default new WSClient;
