class Token {
	constructor(private token: any) {

	}

	get() {
		return this.token;
	}

	set(token: any) {
		this.token = token;

		return this;
	}

	clear() {
		this.token = null;

		return this;
	}

	toString() {
		return this.token;
	}

	toJSON() {
		return JSON.stringify({ token: this.token });
	}
}

export { Token };
