<template>
	<div class="sidebar sidebar-dark sidebar-main sidebar-expand-md" :class="{'sidebar-fullscreen' : sidebarFullscreen}">
		<!-- <div class="sidebar-user">
			<div class="card-body">
				<div class="media">
					<div class="mr-2">
						<img :src="userAvatar" width="38" height="38" class="rounded-circle" alt />
					</div>

					<div class="media-body">
						<div class="media-title font-weight-semibold">Тест Наталья Регистратор</div>
						<div class="font-size-xs opacity-50">Тестовая версия ПАБ</div>
						<div class="font-size-xs opacity-50">Отделение ПАБ</div>
					</div>

					<div class="ml-1 align-self-center">
						<a href="#" class="text-white">
							<i class="icon-cog3"></i>
						</a>
					</div>
				</div>
			</div>
		</div>-->
		<div class="sidebar-mobile-toggler text-center">
			<a href="#" class="sidebar-mobile-main-toggle" @click="onSidebarHide">
				<i class="icon-arrow-left8"></i>
			</a>
			Главное меню
			<a href="#" class="sidebar-mobile-expand" @click="onChangeSidebarFullscreen">
				<i class="icon icon-minimize"></i>
				<i class="icon icon-maximize"></i>
			</a>
		</div>

		<div class="sidebar-content">
			<div class="card card-sidebar-mobile">
				<ItemsSidebar :items="menuItems" ref="sidebar" />
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue';

import { closeSidebar, menuItems, sidebarFullscreen } from "../layouts";

import ItemsSidebar from "./ItemsSidebar";
import { config } from "@/config";
import { useStore } from 'vuex';

export default {
	components: {
		ItemsSidebar
	},

	setup() {
		const { state } = useStore();

		return {
			userAvatar: computed(() => `${config.server}/api/images/avatar/${state.user.avatar ? state.user.avatar : null}`),
			menuItems: computed(() => menuItems.value),

			sidebarFullscreen: computed(() => sidebarFullscreen.value),

			onChangeSidebarFullscreen: () => sidebarFullscreen.value = !sidebarFullscreen.value,

			onSidebarHide: () => closeSidebar()
		}
	}
};
</script>

<style>
</style>
