import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dropdown-divider"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "icon icon-minus"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.contextMenu.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "context-menu dropdown-menu show",
        style: _normalizeStyle({top: $setup.contextMenu.top, left: $setup.contextMenu.left})
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.contextMenu.items, (item, key) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
            (item.caption == '-')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1))
              : (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "dropdown-item",
                  href: "#",
                  onClick: _withModifiers(item.onClick, ["prevent"])
                }, [
                  (item.icon)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { key: 0 }))
                    : (_openBlock(), _createElementBlock("i", _hoisted_3)),
                  _createTextVNode(" " + _toDisplayString(item.caption), 1)
                ], 8, _hoisted_2))
          ], 64))
        }), 128))
      ], 4))
    : _createCommentVNode("", true)
}