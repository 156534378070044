import { ref } from "vue";
import DBStoreRecord from "@/core/db_store_record";
export default class DBLabelController {
	public store: DBStoreRecord;
	public data: any;
	public form: any;

	public structure: any;
	public field: any;

	constructor(protected props: any) {
		this.form = props.form;
		this.store = props.form.store;

		this.field = ref(this.props.field);

		this.structure = this.getFieldModel(this.field.value.split('.'), this.store.state);
	}

	getFieldModel(fields: any, structure: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			return this.getFieldModel(fields, structure.fields[field].model);
		} else {
			return structure.fields[field];
		}
	}

	get value() {
		return this.store.getNotion(this.field.value);
	}
}
