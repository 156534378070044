export default {
	forms: {
		list: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {
					},
					description: {
					}
				}
			}
		},
		select: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {
					},
					description: {
					}
				}
			}
		},
		edit: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {
					},
					description: {
					}
				}
			}
		}
	}
}