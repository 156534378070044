<template>
	<input type="text" v-mask="config" :value="display" @input="onInput" />
</template>

<script>
import mask from './directive'
import tokens from './tokens'
import masker from './masker'

export default {
	name: 'InputMask',

	props: {
		value: [String, Number],
		mask: {
			type: String,
			default: null
		},
		masked: {
			type: Boolean,
			default: true
		},
		tokens: {
			type: Object,
			default: () => tokens
		}
	},

	directives: { mask },

	data() {
		return {
			lastValue: null,
			display: this.value
		}
	},

	watch: {
		value(newValue) {
			if (newValue !== this.lastValue) {
				this.display = newValue
			}
		},
		masked() {
			this.refresh(this.display)
		}
	},

	computed: {
		config() {
			return {
				mask: this.mask,
				tokens: this.tokens,
				masked: this.masked
			}
		}
	},

	methods: {
		onInput(e) {
			if (e.isTrusted) return

			this.refresh(e.target.value)
		},

		refresh(val) {
			this.display = val;

			const value = masker(val, this.mask, this.masked, this.tokens);

			if (value !== this.lastValue) {
				this.lastValue = value

				this.$emit('input', {
					target: {
						value
					}
				})
			}
		}
	}
}
</script>
