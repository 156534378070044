export default ({
	forms: {
		offLine: true,
		list: {
			config: {
				sorting: true,
				fields: {
					id: {
						config: {
							visible: false,
							width: '250px'
						}
					},
					code: {},
					name: {},
					namefull: {},
					fizlico: {},
					table: {
						description: 'Таблица',
						type: {
							table: {
								key: "id",
								description: "Offline таблица",
								fields: {
									id: {
										type: "UUID",
										config: {
											visible: true
										}
									},
									name: {
										description: 'Наименование',
										type: "STRING"
									},
									country: {
										description: 'Страна',
										type: {
											reference: 'country'
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
})