let instance: any = null;

class EventBus {
	private events: any;
	constructor() {
		if (!instance) {
			this.events = {};
			//eslint-disable-next-line
			instance = this;
		}

		return instance;
	}

	$emit(event: any, message: any) {
		if (!this.events[event]) return;

		const callbacks = this.events[event];

		for (let i = 0, l = callbacks.length; i < l; i++) {
			const callback = callbacks[i];
			callback.call(this, message);
		}
	}

	$on(event: any, callback: any) {
		if (!this.events[event])
			this.events[event] = [];

		this.events[event].push(callback);
	}

	$off(event: any, callback: any) {
		if (!this.events[event]) return;

		const callbacks = this.events[event];

		for (let i = 0, l = callbacks.length; i < l; i++) {
			if (callbacks[i] == callback) {
				callbacks.splice(i, 1);
				break;
			}
		}
	}
}

export default new EventBus();