import Swal from "sweetalert2";
import { edit } from "@/core/db";
import { toastError } from '@/helpers/toastify';
import { computed, nextTick, ref } from "vue";
import stateStore from "@/store";
import openContextMenu from "@/components/openContextMenu";
import { getModel } from "@/tables";
import DBStore from "@/core/db_store";
import { mergeDeep } from "@/helpers/utils";
import { query } from "@/api/db";

export default class DBTreeController {
	public store: DBStore;

	public tree: any;
	public data: any;
	public settings: any;
	public access: any;
	public readonly: any;
	public panelFun: any;
	public standardCommands: any;

	private thisNode: any;

	constructor(protected props: any, protected emit: any) {
		this.tree = ref(null);
		this.data = ref([]);
		this.panelFun = ref([]);

		this.store = props.store;

		this.settings = Object.assign(
			{
				id: null,
				height: null,
				panelFun: true,
				notion: (data: any) => data.text
			},
			props.config
		)

		const model: any = getModel(props.table);

		this.access = model.access;

		this.readonly = computed(() => !(this.access.create || this.access.update || this.access.delete));

		this.standardCommands = {
			edit: {
				caption: 'Изменить',
				title: 'Изменить',
				icon: <i class="icon icon-edit"></i>,
				class: 'btn btn-edit',
				onClick: () => this.nodeEdit(this.thisNode)
			},
			view: {
				caption: 'Просмотр',
				title: 'Просмотр',
				icon: <i class="icon icon-eye"></i>,
				class: 'btn btn-edit',
				onClick: () => this.nodeEdit(this.thisNode)
			},
			add: {
				caption: 'Добавить',
				title: 'Добавить',
				icon: <i class="icon icon-plus-circle"></i>,
				class: 'btn btn-add',
				onClick: () => this.nodeAdd(this.thisNode)
			},
			addFolder: {
				caption: 'Добавить группу',
				title: 'Добавить группу',
				icon: <i class="icon icon-folder"></i>,
				class: 'btn btn-add',
				onClick: () => this.nodeAdd(this.thisNode, true)
			},
			copy: {
				caption: 'Копировать',
				title: 'Копировать',
				icon: <i class="icon icon-file"></i>,
				class: 'btn btn-copy',
				onClick: () => this.nodeCopy(this.thisNode)
			},
			delete: {
				caption: 'Удалить',
				title: 'Удалить',
				icon: <i class="icon icon-x-circle"></i>,
				class: 'btn btn-delete',
				onClick: () => this.nodeDelete(this.thisNode)
			}
		}

		this.createPanelFun([
			{
				edit: this.access.update ? {} : null,
				add: this.access.create ? {} : null,
				addFolder: this.access.create ? {} : null,
				copy: this.access.create ? {} : null,
				delete: this.access.delete ? {} : null
			}
		])
	}

	createPanelFun(items: any) {
		this.panelFun.value.splice(0, this.panelFun.value.length);

		for (const item of items) {
			const panel: any = {};

			for (const key in item) {
				if (item[key]) {
					panel[key] = {};

					if (key in this.standardCommands) {
						Object.assign(panel[key], this.standardCommands[key], item[key]);
					} else {
						Object.assign(panel[key], item[key]);
					}
				}
			}

			this.panelFun.value.push(panel);
		}
	}

	getContextMenu(node: any) {
		const items = [];

		if (this.access.update)
			items.push({
				icon: <i class="icon icon-edit"></i>,
				caption: 'Изменить',
				onClick: () => this.nodeEdit(node)
			})

		if (this.access.create) {
			items.push({
				icon: <i class="icon icon-plus-circle"></i>,
				caption: 'Добавить',
				onClick: () => this.nodeAdd(node)
			})

			items.push({
				icon: <i class="icon icon-folder"></i>,
				caption: 'Добавить раздел',
				onClick: () => this.nodeAdd(node, true)
			})

			items.push({
				icon: <i class="icon icon-file"></i>,
				caption: 'Копировать',
				onClick: () => this.nodeCopy(node)
			})
		}

		if (this.access.delete)
			items.push({
				icon: <i class="icon icon-x-circle"></i>,
				caption: node.folder ? 'Удалить раздел' : 'Удалить',
				onClick: () => this.nodeDelete(node)
			})

		return items;
	}

	active(node: any) {
		const tree = this.tree.value;

		this.thisNode = node;

		tree.setActive(node);

		this.emit('active', node.data);
	}

	async nodeEdit(node: any) {
		await edit({
			table: this.props.table,
			data: { id: node.id },
			form: node.folder ? 'editgroup' : 'edit'
		});
	}

	async nodeCopy(node: any) {
		const data = await edit({
			copy: true,
			table: this.props.table,
			data: { id: node.id },
			form: node.folder ? 'editgroup' : 'edit'
		});

		if (data) {
			const tree = this.tree.value;

			if (node.folder) node.expanded = true;

			const newNode = tree.createNode(data, node.parent.children, node.parent);
			this.active(newNode);
		}
	}

	async nodeDelete(node: any) {
		const deleteNode = async () => {
			await query({
				table: this.props.table,
				method: 'delete',
				data: {
					id: node.id
				}
			});

			this.tree.value.deleteNode(node.id);
		}

		if (node.folder) {
			if (node.children.length == 0) {
				Swal.fire({
					title: 'Удалить папку?',
					showCancelButton: true,
					confirmButtonText: 'Да',
					cancelButtonText: 'Отмена'
				}).then(async ({ value }) => value && await deleteNode())
			} else {
				toastError('Удалите дочерние элементы');
			}
		} else {
			Swal.fire({
				title: 'Удалить элемент?',
				showCancelButton: true,
				confirmButtonText: 'Да',
				cancelButtonText: 'Отмена'
			}).then(async ({ value }) => value && await deleteNode())
		}
	}

	async nodeAdd(node: any, folder = false) {
		const data = await edit({
			table: this.props.table,
			data: {
				parent: node.folder ? node.id : (node?.parent?.id ? node.parent.id : '00000000-0000-0000-0000-000000000000'),
				node: folder
			},
			form: folder ? 'editgroup' : 'edit'
		});

		if (data) {
			const tree = this.tree.value;

			if (node.folder) {
				node.expanded = true;

				const newNode = tree.createNode(data, node.children, node);
				this.active(newNode);
			} else {
				if (node.parent) {
					const newNode = tree.createNode(data, node.parent.children, node.parent);
					this.active(newNode);
				} else {
					const newNode = tree.createNode(data, tree.tree);
					this.active(newNode);
				}
			}
		}
	}

	createNode(data: any, node: any) {
		const tree = this.tree.value;

		const newNode = tree.createNode(data, node.parent.children, node.parent);
		this.active(newNode);
	}

	async textChanged(newText: any, prevText: any, active: any) {
		await query({
			table: this.props.table,
			method: "update",
			data: {
				id: active.id,
				name: newText
			}
		});
	}

	async draggingFinish(dragElement: any, item: any, dragmode: any) {
		await query({
			table: this.props.table,
			method: "treemove",
			from: dragElement.id,
			to: item.id,
			mode: dragmode
		});
	}

	async dblclick(node: any) {
		if (this.props.selectmode) {
			this.emit('select', node.data);
		} else {
			await this.nodeEdit(node);
		}
	}

	contextMenu(e: any, node: any) {
		openContextMenu(e, this.getContextMenu(node));
	}

	async fetchData(options: any = {}) {
		stateStore.state.load = true;

		const queryData = mergeDeep({
			table: this.store.name,
			method: 'tree',
			params: this.store.fetchParams(options),
			data: {
				deep: this.props.deep,
				id: this.settings.id
			}
		});

		const response: any = await query(queryData);

		stateStore.state.load = false;

		this.data.value = response.data;

		if (this.data.value.length > 0) {
			if (this.props.selectmode) {
				nextTick(() => {
					let node = this.tree.value.findNode(this.settings.id);

					if (!node) node = this.tree.value.findNode(this.data.value[0].id);

					this.active(node);
				})
			} else {
				nextTick(() => {
					const node = this.tree.value.findNode(this.data.value[0].id);
					if (node) this.active(node);
				})
			}
		}
	}

	async nodeOpen(node: any, controller: any) {
		if (this.props.deep != 255 && !node.loaded) {
			node.loaded = true;

			stateStore.state.load = true;

			const queryData = mergeDeep({
				table: this.store.name,
				method: 'read',
				params: this.store.fetchParams({
					filters: [
						{
							field: `"${this.store.name}"."parent"`,
							value: node.id
						}
					],
					orderby: `"${this.store.name}"."position"`
				})
			});

			const response: any = await query(queryData);

			stateStore.state.load = false;

			controller.loadNodeData(node, response.data.rows);
		}
	}
}