<script lang="jsx">
import { h } from 'vue'
import { closeSidebar } from "../layouts";

export default {
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},

	watch: {
		path() {
			if (this.items.length > 0) {
				this.clearActive(this.items);
				this.setActive(this.items, this.path);
			} else {
				setTimeout(() => {
					this.clearActive(this.items);
					this.setActive(this.items, this.path);
				}, 300)
			}
		}
	},

	computed: {
		path() {
			return this.$route.path
		}
	},

	methods: {
		setActive(items, path) {
			let active = false;

			for (const item of items) {
				item.active = false;

				if (item.route == path) {
					item.active = true;

					active = true;

					break;
				}

				if (item.items && item.items.length > 0) {
					if (this.setActive(item.items, path)) {
						item.active = true;
						active = true;

						break;
					}
				}
			}

			return active;
		},

		clearActive(items) {
			items.forEach(el => {
				if (el.items) {
					this.clearActive(el.items);
					// el.active = false;
				} else {
					el.active = false;
				}
			});
		},

		onClick(e, item) {
			e.preventDefault();

			this.clearActive(this.items);

			item.active = item.active ? !item.active : true;

			if (!item.items && this.$route.path != item.route) {
				this.$router.push(item.route);

				closeSidebar();
			}
		},

		link(item) {
			const { header, title, icon, route, active = false } = item;

			return h(
				"a",
				{
					class: { "nav-link": true, active },
					title: title ? title : header,
					href: route ? route : "#",
					onClick: e => this.onClick(e, item)
				},
				[
					h("i", { class: icon }),
					h("span", header)
				]
			);
		},

		menuItems(items) {
			return items.map(item => {
				const { active = false, items = [] } = item;

				const elements = [
					this.link(item)
				];

				if (items.length > 0) {
					elements.push(
						h(
							"ul",
							{
								class: "nav nav-group-sub",
								style: active ? "display: block;" : ""
							},
							this.menuItems(items)
						)
					);
				}

				return h(
					"li",
					{
						class: items.length > 0 ? (active ? "nav-item nav-item-submenu nav-item-open" : "nav-item nav-item-submenu") : "nav-item"
					},
					elements
				);
			});
		}
	},

	render() {
		const menus = [];

		for (const item of this.items) {
			if (item.header)
				menus.push(
					<li class="nav-item-header">
						<div class="text-uppercase font-size-xs line-height-xs">
							{item.header}
						</div>
						<i class="icon icon-more-horizontal" title="Main"></i>
					</li>
				);
			menus.push(this.menuItems(item.items));
		}

		return h("ul", { class: "nav nav-sidebar" }, menus);
	}
};
</script>

<style>
</style>
