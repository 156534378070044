<template>
	<div class="body-block">
		<div class="page-content pageContainer">
			<div class="content-wrapper">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Empty"
}
</script>
