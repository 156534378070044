<template>
	<div :class="{'input-group-append': true, 'input-important': important }">
		<template v-for="(item, key) of triggers" :key="key">
			<button
				class="btn btn-trig dropdown-toggle"
				:class="{'important-trigger': important, 'border-primary': required, 'border-success': verified && valid, 'border-danger': verified && !valid, 'input-important': important}"
				type="button"
				:title="item.title"
				@click="openMenu(item.menu)"
				v-if="item.menu"
			>
				<template v-if="typeof item.text==='object'">
					<component :is="item.text"></component>
				</template>
				<template v-else>{{item.text}}</template>
			</button>
			<button
				class="btn btn-trig"
				:class="{'important-trigger': important, 'border-primary': required, 'border-success': verified && valid, 'border-danger': verified && !valid, 'input-important': important}"
				type="button"
				:title="item.title"
				@click="item.onClick"
				v-else
			>
				<template v-if="typeof item.text==='object'">
					<component :is="item.text"></component>
				</template>
				<template v-else>{{item.text}}</template>
			</button>
		</template>
	</div>
</template>

<script >
import { computed, defineComponent } from 'vue'

export default defineComponent({
	props: {
		controller: {
			type: Object,
			default: () => ({})
		}
	},

	setup(props) {
		const openMenu = async (items) => await props.controller.openMenu(items);

		return {
			openMenu,
			triggers: computed(() => props.controller.triggers()),
			verified: computed(() => props.controller.verified.value),
			valid: computed(() => props.controller.valid.value),
			required: computed(() => props.controller.required.value),
			important: computed(() => props.controller.props.important)
		}
	}
})
</script>

<style scoped>
.important-trigger {
	border: 2px solid #26a69a;
	padding: 0.3375rem 0.775rem;
}

.completed-trigger {
	border: 1px solid #3876bd;
}

.input-important {
	margin-left: -2px !important;
}
</style>