import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"width":"20px"}
}
const _hoisted_2 = {
  key: 1,
  style: {"width":"25px"}
}
const _hoisted_3 = {
  key: 2,
  style: {"width":"40px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tfoot", null, [
    _createElementVNode("tr", null, [
      (_ctx.config.menu)
        ? (_openBlock(), _createElementBlock("th", _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.config.rowSelect)
        ? (_openBlock(), _createElementBlock("th", _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.config.numbering)
        ? (_openBlock(), _createElementBlock("th", _hoisted_3))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.fields, (field, fieldkey) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: fieldkey }, [
          (field.config.visible && !field.config.hide)
            ? (_openBlock(), _createElementBlock("th", {
                key: 0,
                style: _normalizeStyle(field.config.footerStyle ? field.config.footerStyle: null)
              }, _toDisplayString(_ctx.controller.footerData[fieldkey] ? _ctx.controller.footerData[fieldkey] : ''), 5))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}