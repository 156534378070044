<template>
	<div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" v-if="breadCrumb.length">
		<div class="d-flex">
			<div class="breadcrumb">
				<template v-for="(item, i) of breadCrumb" :key="i">
					<router-link :to="item.route" class="breadcrumb-item" v-if="item.route">
						<i :class="`${item.icon} mr-2`" v-if="item.icon"></i>
						{{item.caption}}
					</router-link>
					<span class="breadcrumb-item active" v-else>
						<i :class="`${item.icon} mr-2`" v-if="item.icon"></i>
						{{item.caption}}
					</span>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { breadCrumb } from "./layouts";

export default {
	setup() {
		return {
			breadCrumb
		}
	}
}
</script>

<style>
</style>
